import { gsap } from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import ScrollToPlugin from "gsap/ScrollToPlugin";

document.addEventListener('DOMContentLoaded', () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);

    window.anims = () => {
        const reviews = document.querySelector('.js-reviews'),
              products = document.querySelector('.js-products');

        const thisIndex = (elm) => {
            let nodes = elm.parentNode.childNodes, node, i = 0, count = 0;
            while( (node = nodes.item(i++)) && node != elm ) {
                if( node.nodeType == 1 ) count++;
            }
            return count;
        };

        gsap.utils.toArray(".a-fadeInChildren > *").forEach(function(section) {
            gsap.from(section, {
                scrollTrigger: {
                    trigger: section,
                    start: '-50px bottom',
                    toggleActions: "play complete complete reset",
                },
                opacity: 0,
                duration: 1,
                y: 50
            });
        });

        if (document.querySelector('.c-charts')) {
            let currentSlide,
                carousel,
                carousel_status = false,
                cols = gsap.utils.toArray(".c-charts .js-cols"),
                nav_li = document.querySelectorAll(".c-charts__nav li"),
                nav_li_active,
                status = false,
                panels = gsap.utils.toArray(".c-charts__page"),
                scrollTween,
                article = gsap.utils.toArray(".c-charts .js-cols .o-article");

            const navLinks = gsap.utils.toArray(".c-charts__nav li a");

            navLinks.forEach(el => {
                el.addEventListener("click", e => {
                    e.preventDefault();

                    ScrollTrigger.refresh();
                    let offset = -10,
                        num = thisIndex(e.currentTarget.parentNode);

                    window.innerWidth > 1024 ?
                        currentSlide <= num ? offset = -30 : offset = 30 :
                        offset = 129;

                    nav_li_active = e.currentTarget.parentNode;
                    console.log(nav_li_active);

                    gsap.to(window, {duration: .5, scrollTo: {y: e.currentTarget.getAttribute('href'), offsetY: offset} });
                });
            });

            const goToSection = (i) => {
                currentSlide = i;
            }

            const navTrigger = (i) => {
               if (window.innerWidth > 1024) {
                    for (let k = 0; k < nav_li.length; k++) {
                        nav_li[k].classList.remove('is-active');
                    }
                    nav_li[i].classList.add('is-active');
                } else {
                    for (let k = 0; k < nav_li.length; k++) {
                        nav_li[k].classList.remove('is-active');
                    }
                    nav_li[i].classList.add('is-active');

                }
            }

            const initCharts = function() {

                ScrollTrigger.matchMedia({
                    "(min-width: 1025px)": function() {

                         gsap.from(document.querySelector('.c-charts'), {
                            id: 'nav',
                            scrollTrigger: {
                                trigger: '.c-charts',
                                pin: '.c-charts__nav',
                                start: "top top",
                                end: "bottom bottom",
                                onEnter: ({ progress, direction, isActive }) => {
                                    gsap.to('.c-charts__nav', {
                                        autoAlpha: 1,
                                        x: 0,
                                        stagger: 0.25,
                                    });
                                }
                            }
                        });
                    },

                    "(max-width: 1024px)": function() {

                         gsap.from(document.querySelector('.c-charts'), {
                            scrollTrigger: {
                                trigger: '.c-charts',
                                pin: '.c-charts__nav',
                                pinSpacing: false,
                                start: "top 70px",
                                end: "bottom bottom",
                                onEnter: () => {
                                    document.querySelector('.c-topbar').classList.add('no-border');
                                },
                                onLeave: () => {
                                    document.querySelector('.c-topbar').classList.remove('no-border');
                                },
                                onLeaveBack: ({ progress, direction, isActive }) => {
                                    document.querySelector('.c-topbar').classList.remove('no-border');
                                },

                                onEnterBack: ({ progress, direction, isActive }) => {
                                    document.querySelector('.c-topbar').classList.remove('no-border');
                                }
                            }
                        });
                    }
                })

                cols.forEach((item, i) => {

                    ScrollTrigger.matchMedia({
                        "(min-width: 1025px)": function() {
                            gsap.from(item, {
                                ease: "none",
                                scrollTrigger: {
                                    trigger: item,
                                    start: "top 70px",
                                    end: "bottom bottom",
                                    onToggle: self => self.isActive ? console.log('') : goToSection(i),
                                    onEnter: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    },

                                    onLeave: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    },

                                    onLeaveBack: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    },

                                    onEnterBack: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    }
                                }
                            });
                        },
                        "(max-width: 1024px)": function() {
                            gsap.from(item, {
                                ease: "none",
                                scrollTrigger: {
                                    trigger: item,
                                    start: "top 140px",
                                    end: "bottom bottom",
                                    onToggle: self => self.isActive ? console.log('') : goToSection(i),
                                    onEnter: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    },

                                    onLeave: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    },

                                    onLeaveBack: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    },

                                    onEnterBack: ({ progress, direction, isActive }) => {
                                        navTrigger(i);
                                    }
                                }
                            });
                        }
                    });

                    ScrollTrigger.matchMedia({
                        "(min-width: 1025px)": function() {
                            gsap.from(item.querySelector('.o-article'), {
                                id: 'art'+i,
                                ease: "none",
                                scrollTrigger: {
                                    trigger: item,
                                    scrub: 1,
                                    start: 'top bottom',
                                    end: 'top 25%',
                                    onEnter: ({ progress, direction, isActive }) => {
                                        currentSlide = i;
                                        navTrigger(i);
                                    },
                                },
                                opacity: 0,
                                scale: 1.2
                            });
                        }
                    });
                })
            }

            initCharts();
        }

        if (document.querySelector('.c-gallery')) {
            ScrollTrigger.batch(".c-gallery li", {
                onEnter: elements => {
                    gsap.from(elements, {
                        autoAlpha: 0,
                        y: 60,
                        stagger: 0.25,
                        scale: .9
                    });
                },
            });
        }

        if (document.querySelector('.c-hero')) {
            gsap.to(".c-hero .shape", {
                rotate: 40,
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-hero",
                    scrub: 1,
                    start: 'top top',
                },
            });

/*
            gsap.to(".c-hero .c-hero__product", {
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-hero .c-hero__product",
                    scrub: 1,
                    start: 'top top',
                    pin: true,
                    end: 'bottom .box-end'
                },
            });
*/

            gsap.from('.c-hero h1', { autoAlpha: 0, duration: .6, y: 50, delay: .1 })
            gsap.from('.c-hero p', { autoAlpha: 0, duration: .6, y: 50, delay: .2 })
            gsap.from('.c-hero h1 span', { autoAlpha: 0, duration: 1, y: -20, delay: .3 })

            gsap.from('.c-hero img.box', { autoAlpha: 0, duration: .6, y: -50, delay: .4 })
            gsap.from('.c-hero img.shape', { autoAlpha: 0, duration: 1, rotate: -45, delay: .6 })

            gsap.from('.c-hero .signs', { autoAlpha: 0, duration: 2, delay: .7 })
        }

        if (document.querySelector('.c-topbar')) {
            gsap.to(".c-topbar .c-topbar__line", {
                y: 70,
                height: '2px',
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-topbar",
                    scrub: 1,
                    start: 'top top',
                },
            });

            gsap.from(".c-topbar .c-topbar__line", {
                scaleX: 2,
                ease: "none",
                scrollTrigger: {
                    trigger: ".c-topbar",
                    scrub: 1,
                    start: 'top top',
                },
            });
        }


        const productsAnim = function() {
            gsap.from(products, {
                scrollTrigger: {
                    trigger: products,
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },
                xPercent: -15
            });
        };

        const reviewsAnim = function() {
            gsap.from(reviews, {
                scrollTrigger: {
                    trigger: reviews,
                    scrub: 2.2,
                    start: 'top bottom',
                    end: 'top center'
                },
                xPercent: 15
            });
        };

        reviews ? reviewsAnim() : false;
        products ? productsAnim() : false;
    };

}, false)


/*
                let fromBottom = function(el, reverse) {
                    gsap.fromTo(
                        el,
                        {
                            opacity: 1,
                            clipPath: "polygon(0 100%, 100% 100%, 100% 100%, 0 100%)"
                        },
                        {
                            clipPath: "polygon(0 100%, 100% 100%, 100% 0%, 0 0%)",
                            duration: 2,
                            ease: "power2"
                        }
                    );
                }

                let fromLeft = function(el, reverse) {
                    gsap.fromTo(
                        el,
                        {
                            opacity: 1,
                            clipPath: "polygon(0 0, 0 0, 0 100%, 0% 100%)",
                        },
                        {
                            clipPath: "polygon(0 0, 100% 0, 100% 100%, 0% 100%)",
                            duration: 2,
                            ease: "power2"
                        },
                    );
                }
*/
