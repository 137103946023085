//import maplibregl from 'maplibre-gl';
//require('../../node_modules/maplibre-gl/dist/maplibre-gl.css');

let map = false;
let markers = [];
const key = 'V2JAD7EjpWrpqpzmJ2VG';

export function getMapKey() {
    return key;
}

export function loadMap(offer, data, lat = null, lng = null, setCenter = false) {
    if (!document.getElementById('map')) {
        return;
    }

    // if (offer) {
    //     let icon = document.createElement('div');
    //     icon.className = 'marker';
    //
    //     const map = new maplibregl.Map({
    //         container: 'map', // container id
    //         style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${key}`, // style URL
    //         center: [data[10], data[9]], // starting position [lng, lat]
    //         zoom: 6, // starting zoom
    //     });
    //     map.addControl(new maplibregl.NavigationControl(), 'bottom-right');
    //     map.scrollZoom.disable();
    //
    //     new maplibregl.Marker(icon)
    //       .setLngLat([data[10], data[9]])
    //       .addTo(map);
    // } else {
        let zoom =  lat && lng ? 7 : 5.5;
        lat = lat ? lat : 52.0;
        lng = lng ? lng : 19.5;
        if (!map) {
            map = new maplibregl.Map({
                container: 'map', // container id
                // style: `https://api.maptiler.com/maps/streets-v2/style.json?key=${key}`, // style URL
                style: `https://api.maptiler.com/maps/bff4d3b2-b171-4192-85c1-402a05eeec9e/style.json?key=${key}`, // style URL

                // style: `https://api.maptiler.com/maps/09781b4f-3d4d-4f3a-bd4e-7aa69b1641f9?key=${key}`, // style URL
                center: [lng, lat], // starting position [lng, lat]
                zoom: zoom, // starting zoom
            });
            map.addControl(new maplibregl.NavigationControl(), 'bottom-right');
            map.scrollZoom.disable();
        } else {
            if (setCenter) {
                map.jumpTo({
                    center: [lng, lat],
                    zoom: zoom
                });
            }
        }

        let list = [];
        markers.forEach((item) => {
            item.remove();
        });

        markers = [];

        data.forEach((item) => {
            if (!isNaN((item[9])) && !isNaN((item[10])) && list.indexOf(item[9] + item[10]) === -1) {
                list.push(item[9] + item[10])

                let icon = document.createElement('div');
                icon.className =  item[7] === 'Oddział PROCAM' ? 'marker-department' : 'marker-distribution icon-pin';
                icon.dataset[9] = item[9];
                icon.dataset[10] = item[10];
                icon.onclick = function (e) {
                    map.flyTo({center: [item[10], item[9]], zoom: 10});

                    document.querySelectorAll('.o-maptooltip__close').forEach((element) => {
                        element.parentNode.style.display = 'none';
                    });
                    e.currentTarget.parentNode.appendChild(e.currentTarget);
                    e.currentTarget.children[0].style.display = 'block';
                }

                icon.innerHTML = `<div class="o-maptooltip">
                    <i class="o-maptooltip__close"></i>
                    <img class="icon-department" src="img/base/logo.webp" alt="" width="30" height="30" />
                    <i class="icon-distribution icon-pin"></i>
                    <p><strong>${item[0]}</strong></p><p>${item[1]}</p><p>${item[3]}, ${item[2]}</p><p>tel. ${item[4]}</p>
                </div>`

                let marker = new maplibregl.Marker(icon)
                  .setLngLat([item[10], item[9]])
                  .addTo(map);

                markers.push(marker)
            }
        })

        document.querySelectorAll('.o-maptooltip__close').forEach((element) => {
            element.addEventListener('click', (e) => {
                e.stopPropagation();
                element.parentNode.style.display = 'none';
            })
        })

        // map.on('sourcedata', function(e) {
        //     if (document.body.classList.contains('page-search-filtered')) {
        //         e.isSourceLoaded ? window.runScroll('#results', 0) : false;
        //     }
        // });

    // }
};
