import GLightbox from '../../node_modules/glightbox/dist/js/glightbox.js';
require('../../node_modules/glightbox/dist/css/glightbox.css');

document.addEventListener('DOMContentLoaded', () => {

    let lightbox;
    if (document.querySelectorAll('.js-glightbox').length > 0) {
        lightbox = GLightbox({ 
            selector: '.js-glightbox',
            draggable: false
        })
        
        lightbox.on('open', () => {
            window.hideMenu();
        });
    }
    
    if (document.querySelectorAll("[href*='youtu']").length > 0) {
        const video = GLightbox({
            selector: ".js-video"
        });
    }

}, false);
